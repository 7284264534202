<template>
  <div class="tabs-container clearfix" id="itinerary">
    <div class="tabs-pad">
      <div class="tabs-container-tour clearfix" v-for="(item, i) in itineraryData" :key="i">
          <div class="tabs_blue_content_day" :style="`background: url('${speedSizeDomain}/assets/img/icon_day.png') no-repeat center top;`"><span class="font_13">יום</span><br />{{item.day}}</div>
          <div class="tabs_blue_content_text">
              <h3 class="tabs_blue_content_title">{{`יום ${item.day}: ${item.header}`}}</h3>
              <p>{{item.text}}</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import imageUrlMixin from '@/utils/imageUrlMixin';

export default {
  mixins: [imageUrlMixin],
  data() {
    return {
      lang: 'he',
      itineraryData: [],
    };
  },
  watch: {
    appDealPageData() {
      this.fillData();
    },
  },
  async created() {
    if (!this.$route.query.dealId) return;
    await this.fetchData();
  },
  computed: {
    ...mapGetters({
      appDealPageData: 'GET_APP_DEAL_DATA',
    }),
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('FETCH_APP_DEAL_PAGE_DATA', { dealId: this.$route.query.dealId, lang: this.lang });
    },
    fillData() {
      if (this.appDealPageData) {
        this.itineraryData = this.appDealPageData?.itinerary?.itineraryDetail || [];
      }
    },
  },
};
</script>

<style scoped>
  .tabs_blue_content_day {
    color: #ba1600;
    float: right;
    font-family: Arial;
    font-size: 12px;
    font-weight: 700;
    height: 45px;
    line-height: 16px;
    margin-left: 10px;
    padding: 9px 8px 3px;
    text-align: center;
    width: 40px;
  }
  .tabs_blue_content_day .font_13 {
    font-size: 11px;
  }
  .clearfix:before, .clearfix:after {
    content: " ";
    display: table;
  }
</style>
